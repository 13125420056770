@import "Receipt.css";
@import "StartRide.css";
@import "Trip.css";

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.relative {
  position: relative;
}

.absolute-fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.background {
  height: 100%;
  width: 100%;
}

.body {
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-action {
  background: #ff5436;
  border: 1px;
  border-radius: 6px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  padding: 20px;
  text-transform: uppercase;
  width: 60%;
}

.center {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.screen {
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spin-header {
  background: white;
  height: 66px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  width: 100%;
}

.spin-logo {
  height: 26px;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 76px;
}

.text {
  margin: 5px;
}

.title {
  font-size: 28px;
  margin: 5px;
}