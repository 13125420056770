.banner-alert {
  align-items: center;
  background: #e90000;
  display: flex;
  height: 72px;
  left: 0px;
  position: absolute;
  top: 66px;
  width: 100%;
}

.banner-icon {
  height: 35px;
  margin-left: 10px;
  width: 35px;
}

.banner-text {
  color: white;
  font-size: 12px;
  margin: 10px;
}

.body-unlocking {
  background: #ff5436;
  flex: 4;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel__dot {
  background: #eeeeee;
  border: none;
}

.carousel__dot-group {
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
}

.carousel__dot--selected {
  background: #ff5436;
}

.circle {
  border-radius: 10px;
  font-size: 0px;
  height: 10px;
  margin: 10px;
  width: 10px;
}

.loading-unlocking {
  align-items: center;
  display: flex;
  height: 50%;
  justify-content: center;
  padding: 10px;
  width: 75%;
}

.rate-unlocking {
  color: white;
  padding: 5px;
}

.rule-text {
  color: #3c3c45;
  margin: 5px;
}

.rule-title {
  font-size: 20px;
  margin: 5px;
}

.rules-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.rules-slide {
  height: 100px;
}

.rules-slider {
  height: 200px;
  margin: 10px;
}

.sad-face {
  margin: 10px;
  width: 60px;
}

.scooter {
  height: 165px;
  position: absolute;
}

.scooter-photo {
  width: 100%;
}

.spinner-unlocking {
  border: 25px;
}

.timer {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: absolute;
}

.timer-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}

.timer-title {
  color: white;
  font-size: 24px;
  text-align: center;
}

.title-unlocking {
  color: white;
  font-size: 30px;
  padding: 5px;
}

.trip-duration {
  color: white;
  font-size: 40px;
  text-align: center;
}
