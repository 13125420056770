.receipt-body {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 95%;
}

.receipt-divider {
  background: #eeeeee;
  height: 2px;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 100%;
}

.receipt-header {
  height: 200px;
  width: 100%;
}

.receipt-header-title {
  color: white;
  font-size: 32px;
  left: 50%;
  margin-left: -150px;
  position: absolute;
  text-align: center;
  top: 115px;
  width: 300px;
}

.receipt-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 100%;
}

.receipt-text {
  font-size: 14px;
}

.receipt-total {
  font-size: 14px;
  font-weight: bold;
}

.receipt-trip-number {
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 10px;
}
