.battery-bar {
  margin-right: 5px;
  max-height: 20px;
  max-width: 50px;
}

.battery-info {
  margin-left: 30px;
  display: flex;
  align-items: center;
}

.btn-applepay {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  background: white;
  flex: 0 1 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.footer-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}

.input-checkbox {
  background: #4f39fa;
  border: 2px solid #4f39fa;
  box-sizing: border-box;
  border-radius: 2px;
  width: 18px;
  height: 18px;
  margin: 5px;
}

.payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.payment-text {
  text-align: center;
}

.price {
  background: #eeeeee;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px;
  margin: 5px;
}

.scooter-image {
  height: 200px;
  width: 200px;
}

.scooter-image-container {
  flex: 1;
  max-height: 60%;
  max-width: 60%;
  overflow: hidden;
}

.trip-start-info {
  background: white;
  position: absolute;
  height: 300px;
  width: 300px;
  border-radius: 6px;
  margin-top: 33px;
}

.unavailable {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  text-align: center;
}

.unavailable .title {
  font-size: 28px;
  margin: 5px;
}

.vehicle-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 100%;
}

.StripeElement {
  width: 200px;
  margin: 0 auto;
}
